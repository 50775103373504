import React from "react";
import styled from "styled-components/macro";
import { Row, Col, Div } from "../../components/grid";
import {
  HiddenXS,
  HiddenMD,
  HiddenLG,
  F12,
  LinkStyled,
  HiddenSM,
  Span,
  Paragraph,
} from "../../components/typography";
import { Image } from "../../components/Image";
import { Money } from "../../containers/commerce";
import DelayedRendering from "../../lib/components/DelayedRendering";

const ItemSubHeader = styled(Div)`
  background: ${({ theme }) => theme.colors.colorGreyLight};
  border-right: 2px solid ${({ theme }) => theme.colors.colorGreyLightAlt};
  border-left: 2px solid ${({ theme }) => theme.colors.colorGreyLightAlt};
  padding: 10px 0;
  padding-left: calc(8.33333% + 5px);
  font-size: 18px;
  font-weight: bold;

  @media (max-width: 767px) {
    padding-left: calc(16.6667% + 5px);
  }
`;
const ItemSubHeaderModularNoStyle = ({
  noHeader,
  title,
  description1,
  description2,
  ...otherProps
}) => (
  <ItemSubHeader {...otherProps}>
    {title && title}
    {description1 && <Paragraph fontSize="12px">{description1}</Paragraph>}
    {description2 && <Paragraph fontSize="12px">{description2}</Paragraph>}
  </ItemSubHeader>
);
const ItemSubHeaderModular = styled(ItemSubHeaderModularNoStyle)`
  & p {
    margin: 0;
    margin-top: 5px;
    font-weight: normal;
  }
`;
/*
  const ItemBanner = styled(Image)`
  `;
*/

export const categories = {
  AlkoholfriOelKasser: {
    name: "alkoholfri-oel",
    categoryURL: "flasker-og-daaser/oel-og-alkohol",
    headline: () => "Alkoholfri Øl",
    packaging: "på flaske og dåse",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Perfekt til frokost og møder</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-oelvand.8650-flaske.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.carlsberg-nordic-flaske.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.skovlyst-ellebryg-flaske.png"
          />
        </Col>
      </Row>
    ),
    // Brug afterItem for at indsætte noget efter et item
    beforeItem: {
      2550: <ItemSubHeader>Alkoholfri øl på flaske</ItemSubHeader>,
      2553: <ItemSubHeader>Alkoholfri øl på dåse</ItemSubHeader>,
      3510: <ItemSubHeader>Letøl</ItemSubHeader>,
    },
    items: [
      2550, // Carlsberg Nordic 24x33
      2490, // Kronenbourg Blanc 0,0
      8650, // Heineken 0,0 %
      8212, // Skovlyst Ellebryg 0,5%
      8322, // Skovlyst Alkoholfri IPA
      3550, // Tuborg Nul 0,0%
      2553, // Carlsberg Nordic Gylden Bryg 0,5% 24x33 dåse
      2573, // Carlsberg Nordic Ale Dåse
      3553, // Tuborg Nul Dåse
      3563, // Tuborg Nul Citrus Dåse
      8653, // Heineken 0,0
      8543, // Skovlyst Alkoholfri Lager Dåse
      3510, // KB Hvidtøl 30x33
      4530, // Vestfyn Light 30x33
    ],
  },
  Oel33ClGlas: {
    name: "oel-33cl-glas",
    categoryURL: "flasker-og-daaser/oel-og-alkohol",
    headline: () => "Øl på flaske",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Tuborg - Carlsberg - Royal - Thy Øko ...</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-oelvand.tuborg-classic-flaske.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.wiibroe-aargansoel-flaske.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.groen-tuborg-flaske.png"
          />
        </Col>
      </Row>
    ),
    beforeItem: {
      2610: <ItemSubHeader>Pilsner</ItemSubHeader>,
      2710: <ItemSubHeader>Stærk Pilsner</ItemSubHeader>,
      2630: <ItemSubHeader>Classic | Mørk Pilsner</ItemSubHeader>,
      8810: <ItemSubHeader>Hvedeøl</ItemSubHeader>,
      8840: <ItemSubHeader>Lys Ale</ItemSubHeader>,
      8720: <ItemSubHeader>Mørk Ale</ItemSubHeader>,
      8820: <ItemSubHeader>Brown Ale</ItemSubHeader>,
      2730: <ItemSubHeader>Porter | Stout</ItemSubHeader>,
      8252: <ItemSubHeader>Juleøl</ItemSubHeader>,
      2550: <ItemSubHeader>Alkoholfri / Letøl</ItemSubHeader>,
    },
    items: [
      // Pilsner
      2610, // Carlsberg Pilsner
      23110, // Corona Extra
      3610, // Grøn Tuborg
      8310, // Hancock Økologisk Pils
      4910, // Heineken
      4900, // Heineken Silver
      8770, // Kronenbourg 1664
      4630, // Royal Øko Pilsner
      1670, // Thy Øko Humle
      1630, // Thy Øko Pilsner
      8570, // Tiger Beer

      // Stærk Pilsner
      2710, // Elephant
      3710, // Fine Festival
      3670, // Guld Tuborg
      370, // Tuborg Julebryg
      3790, // Tuborg Påskebryg

      // Classic | Mørk Pilsner
      2630, // Carlsberg 1883
      4650, // Royal Classic
      3630, // Rød Tuborg
      1640, // Thy Øko Classic
      3650, // Tuborg Classic

      // Hvedeøl
      8810, // Grimbergen Blanche
      8690, // Erdinger Dunkel
      8760, // Erdinger Hefe
      8670, // Hoegaarden
      2480, // Kronenbourg 1664 Blanc

      // Lys Ale
      8840, // Grimbergen Blonde
      8700, // Leffe Blond
      8532, // Skovlyst Humlebryg IPA

      // Mørk Ale
      8720, // Leffe Brune
      8780, // Grimbergen Double Ambree

      // Brown Ale
      8820, // Jacobsen Brown Ale
      8262, // Skovlyst Brown Ale
      12340, // Refsvindinge Ale 16

      // Porter | Stout
      2730, // Gl. Carlsberg Porter

      // Juleøl
      8252, // Skovlyst Julebryg
      3750, // Tuborg Julebryg

      // Alkoholfri / Letøl
      2550, // Carlsberg Nordic Pilsner 0,5%
      2490, // Kronenbourg Blanc 0,0
      8650, // Heineken 0,0 %
      8212, // Skovlyst Ellebryg 0,5%
      8322, // Skovlyst Alkoholfri IPA
      8210, // Skovlyst Ellebryg 0,5% 33cl
      3550, // Tuborg Nul 0,0%
      3510, // KB Hvidtøl
      4530, // Vestfyn Light 2,7%
    ],
  },
  ChristmasGlass: {
    name: "christmas-glass",
    categoryURL: "flasker-og-daaser/oel-og-alkohol",
    headline: () => <React.Fragment>Juleøl på flaske og dåse</React.Fragment>,
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Tuborg - Nørrebro - Jacobsen - Skovlyst ...</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-oelvand.tuborg-julebryg-flaske.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.jacobsen-christmas-ale-75cl.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.skovlyst-julebryg-flaske.png"
          />
        </Col>
      </Row>
    ),
    items: [
      3753, // Tuborg Julebryg dåse
      8252, // Skovlyst Julebryg
      3510, // KB Hvidtøl
      3750, // Tuborg Julebryg flaske
      8331, // Jacobsen Christmas Ale
    ],
    specificCategory: true,
  },
  OelCiderDaase: {
    name: "oel-cider-daase",
    categoryURL: "flasker-og-daaser/oel-og-alkohol",
    headline: () => "Øl & cider på dåse",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Tuborg - Carlsberg - Somersby ...</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-oelvand.carlsberg-daase.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.tuborg-classic-daase.png"
          />
        </Col>
      </Row>
    ),
    items: [
      3613,
      2613,
      2553,
      2573,
      3653,
      2653,
      2683, // Carlsberg 1883 Dåse
      8653, // Heineken 0,0 % Dåse
      8543, // Skovlyst Alkoholfri Lager Dåse
      2823, // Somersby Apple Dåse
      2843, // Somersby Blackberry Dåse
      3753,
      3553, // Tuborg Nul Dåse
    ],
  },
  AlkoholCocktails: {
    name: "cocktails",
    categoryURL: "flasker-og-daaser/oel-og-alkohol",
    headline: () => "Alkoholsodavand & Cocktails",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Somersby - Bacardi Breezer - Nohrlund...</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-oelvand.2960-flaske.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.2810-flaske.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.2930-flaske.png"
          />
        </Col>
      </Row>
    ),
    items: [
      2810,
      2910,
      2930,
      2940,
      2950,
      2960,
      2970,
      2980,
      2990,
      24010,
      24020,
      24030,
      24060,
      24150,
      24070,
      24080,
      24090,
    ],
  },
  Vin: {
    name: "vin",
    categoryURL: "flasker-og-daaser/oel-og-alkohol",
    headline: () => "Vin",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Rødvin - Hvidvin</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-oelvand.16401-flaske.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.15210-flaske.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.2862-kasse.png"
          />
        </Col>
      </Row>
    ),
    items: [2852, 2862],
  },
  KildevandUdenBrus: {
    name: "kildevand-uden-brus",
    categoryURL: "flasker-og-daaser/kildevand",
    headline: () => "Kildevand uden brus",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Aqua d&apos;or - Kildevæld - Denice ...</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-oelvand.9122-flaske.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.aquador-30cl.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.9042-flaske.png"
          />
        </Col>
      </Row>
    ),
    items: [
      9233,
      9222,
      9122,
      9221,
      9042,
      4022, // Egekilde uden brus
      4054, // Ramlosa Premium uden brus
      9302, // Pure Water Vand
      9450, // Acqua Panna 24x25 glas
      9460, // Acqua Panna 24x25
      9462, // Acqua Panna 20x50 glas
      9452, // Acqua Panna 24x50 plast
      9451, // Acqua Panna 12x100 glas
    ],
  },
  KildevandMedBrus: {
    name: "kildevand-med-brus",
    categoryURL: "flasker-og-daaser/kildevand",
    headline: () => "Kildevand med brus",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Aqua d&apos;or - Egekilde - Kurvand - Ramløse ...</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-oelvand.4032-flaske.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.egekilde-brus-30cl.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.5052-flaske.png"
          />
        </Col>
      </Row>
    ),
    items: [
      9420, // S.pellegrino 24x25 glas
      9430, // S.Pellegrino 24x25 pap gl
      9033, // Aquador brus 30cl
      4030, // Egekilde brus 30x30
      5054, // Ramløse Premium 24x33
      4050, // Loka 20x33
      9032, // Aqua d'or blid brus 12x50
      4032, // Egekilde brus 24x50
      5052, // Ramløse Natur 24x50 Plast
      4053, // Ramløse dåse 24x33
      9432, // S.Pellegrino 20x50 glas
      9422, // S.pellegrino 24x50 Plast
      9421, // S.Pellegrino 12x100 glas
      9231, // Aqua d'or brus 6x125
      // 2032, // Kurvand Brus
    ],
  },
  KildevandMedSmag: {
    name: "kildevand-med-smag",
    categoryURL: "flasker-og-daaser/kildevand",
    headline: () => "Kildevand med smag",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Aqua d&apos;or - Egekilde - Kildevæld...</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-oelvand.aquador-brus-hindbaer-solbaer-50cl.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.9142-flaske.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.4042-flaske.png"
          />
        </Col>
      </Row>
    ),
    items: [
      4040, // Egekilde Citrus 30x30
      5064, // Ramløse Premium Cit 24x33
      4060, // Ramløse cit 20x33
      4063, // Ramløse cit dåse
      9062, // Aqua d'or brus cit 12x50
      4042, // Egekilde cit brus 24x50
      5062, // Ramløse Citrus 24x50 Plas

      21212, // Aqua d'or Recover
      21222, // Aqua d'or Protect
      21232, // Aqua d'or Levelup
      21182, // Aqua d\'or Zero Hyldeblomst/Lemonade brus
      21112, // Aqua d'or Zero Appelsin/Mango brus
      9412, // Aqua d'or Zero Blåbær/Solbær brus

      9142, // Kildevæld citr/hyld 24x50
      9152, // Kildevæld Hind/Brom 24x50
      4102, // Egekilde Hylde/Lime brus 24x50
      4072, // Egekilde Hind/Raba 24x50
    ],
  },
  KildevandPaller: {
    name: "kildevand-paller",
    categoryURL: "flasker-og-daaser/kildevand",
    headline: () => (
      <React.Fragment>
        Kildevand i paller{" "}
        <F12>
          (
          <LinkStyled to="/kildevand/vand-med-logo">
            Info om vand med logo
          </LinkStyled>
          )
        </F12>
      </React.Fragment>
    ),
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Aqua d&apos;or - Denice - Kildevand uden pant</HiddenXS>
        <br />
        Paller med kildevand sendes fragtfrit i hele Danmark
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="diverse-oelvand.kildevand-paller.jpg"
          />
        </Col>
      </Row>
    ),
    items: [
      9239,
      9212,
      9039,
      9069,
      // 9931,
      9252,
      9029,
      9309,
      9219,
      // 4022,
      4029,
    ],
  },
  TyskKildevandUdenBrusUdenPant: {
    name: "vand-uden-pant",
    categoryURL: "flasker-og-daaser/kildevand",
    headline: () => "Kildevand uden pant",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Pure Water</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-oelvand.purewater-bio.png"
          />
        </Col>
      </Row>
    ),
    items: [9302],
  },
  SvenskVitaminWell: {
    name: "vitaminvand",
    categoryURL: "flasker-og-daaser/kildevand",
    headline: () => <React.Fragment>Vitamindrik</React.Fragment>,
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Aqua d&apos;or - Vitamin Well ...</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.bobli-vitamins-citron-lime.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.9670-flaske.png"
          />
        </Col>
      </Row>
    ),
    items: [
      // Aquador
      21212, // Aqua d'or Recover
      21222, // Aqua d'or Protect
      21232, // Aqua d'or Levelup
      // Vitamin Well
      9680, // Vitamin Well Summer
      9690, // Vitamin Well Hydrate
      9630,
      9610,
      9660,
      9670,
      9640,
    ],
  },
  AntonSafter: {
    name: "anton-safter",
    categoryURL: "flasker-og-daaser/juice-og-saft",
    headline: () => "Antons Øko safter",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Hyldeblomst - Æblemost - Rabarber ...</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-oelvand.antons-oeko-aeblemost-flaske.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.antons-oeko-solbaer-saft-flaske.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.antons-oeko-appelsin-saft-flaske.png"
          />
        </Col>
      </Row>
    ),
    items: [
      32110, // Antons Øko Æblemost saft
      32120, // Antons Øko Solbær saft
      32130, // Antons Øko Appelsin saft
      32140, // Antons Øko Hyldeblomst saft
      32160, // Antons Øko Hindbær saft
      32240, // Antons Øko Rabarber saft
    ],
  },
  SoebogaardSafter: {
    name: "soebogaard-safter",
    categoryURL: "flasker-og-daaser/juice-og-saft",
    headline: () => "Søbogaard Øko safter",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Hyldeblomst - Æblemost - Rabarber ...</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-oelvand.6140-flaske.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.6110-flaske.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.6120-flaske.png"
          />
        </Col>
      </Row>
    ),
    items: [
      6140,
      6110,
      6111,
      6130,
      6120,
      6190,
      6170,
      6180,
      6260,
      6210,
      6160,
      6240,
      6230,
      6250,
      6280,
      6220,
      6300,
      6290,
      6330,
      6340,
    ],
  },
  Juice25Cl: {
    name: "juice-25cl",
    categoryURL: "flasker-og-daaser/juice-og-saft",
    headline: () => "Juice/Saft 27,5 cl, glas",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Frem Saft - Rynkeby Æblemost - Solita</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-oelvand.3430-flaske.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.3410-flaske.png"
          />
        </Col>
      </Row>
    ),
    items: [
      1140, // Frem Hyldeblomst saft
      3410,
      3430,
    ],
  },
  CocioChokolademaelk: {
    name: "cocio-chokolademaelk",
    categoryURL: "flasker-og-daaser/juice-og-saft",
    headline: () => "Cocio chokolademælk",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Cocio</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-oelvand.cocio-40cl.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.cocio-24cl.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.cocio-25cl-daase.png"
          />
        </Col>
      </Row>
    ),
    items: [3450, 3452, 3453],
  },
  IceTea: {
    name: "icetea",
    categoryURL: "flasker-og-daaser/juice-og-saft",
    headline: () => "Ice Tea",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>
          Nestea Peach - Green Tea Citrus<HiddenMD> - Mango/Pineapple</HiddenMD>{" "}
          ...
        </HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.aquador-mango-ice-tea.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.aquador-black-tea-fersken-zero-50cl.png"
          />
        </Col>
      </Row>
    ),
    items: [21052, 21032],
  },
  /*
  ImportTilbud: {
    name: 'import-tilbud',
    headline: () => (<React.Fragment>Import Tilbud</React.Fragment>),
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Billig Coca-Cola med dansk pantmærke, varedeklaration og afgift</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image maxHeight="42px" imageHeight="42px" name="produkter-oelvand.coca-cola-50cl-1.png" />
        </Col>
      </Row>
    ),
    items: [
      55352,
    ],
  },
  */
  SodavandGlas: {
    name: "sodavand-glas",
    categoryURL: "flasker-og-daaser/sodavand",
    headline: () => "Sodavand glas",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Kurvand - Cola - Fanta - Faxe Kondi ...</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-oelvand.tuborg-squash-25cl.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.coca-cola-25cl.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.carlsberg-kurvand-25cl.png"
          />
        </Col>
      </Row>
    ),
    beforeItem: {
      4050: <ItemSubHeader>Danskvand</ItemSubHeader>,
      5450: <ItemSubHeader>Light / sukkerfri sodavand</ItemSubHeader>,
      5350: <ItemSubHeader>Alm. sodavand</ItemSubHeader>,
    },
    items: [
      4050, // Loka
      1030, // Frem Danskvand
      1040, // Frem Danskvand Citrus
      5054, // Ramløse Premium 24x33
      4030, // Egekilde Brus
      2040, // Carlsberg Kurvand Citrus
      4040, // Egekilde Brus Citrus
      4060, // Ramlösa Citrus
      5064, // Ramløse Premium Cit 24x33

      5450, // Coca Cola Zero
      4330, // Faxe Kondi 0kal
      4110, // Faxe Kondi Appelsin 0kal
      1120, // Frem Appelsin Light
      4360, // Pepsi Max

      5350, // Coca Cola

      1280, // Frem Blå Sport
      1310, // Frem Energisten
      1110, // Frem Appelsin
      1230, // Frem Ananas
      1250, // Frem Abricos
      1270, // Frem Hindbær
      1290, // Frem Grøn Sport
      1000, // Frem Vand Blandet

      4350, // Pepsi
      3110, // Tuborg Squash
      5110, // Fanta Orange
      5310, // Sprite
      // 4110, // Nikoline Appelsin
      4310, // Faxe Kondi
      2170, // Schweppes Lemon Fusion
      2210, // Schweppes Tonic
      2220, // Schweppes Ginger Ale
      5070, // Kinley Citron
      5270, // Kinley Hindbær
      4250, // Passion
      4220, // Ginger Beer

      5000, // Blandet Vand
    ],
  },
  OekologiskSodavand: {
    name: "oekologisk-sodavand-glas",
    categoryURL: "flasker-og-daaser/sodavand",
    headline: () => "Økologisk sodavand glas",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Brdr. Adelhardt, Søbogaard ...</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-oelvand.fejoe-aeble-25cl.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="1px"
            name="produkter-oelvand.fejoe-paere-25cl.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="1px"
            name="produkter-oelvand.6030-flaske.png"
          />
        </Col>
      </Row>
    ),
    items: [
      6010,
      6040,
      6030,
      6250, // Søbo Sport
    ],
  },
  Sodavand2550ClDaaser: {
    name: "sodavand-25-50cl-daaser",
    categoryURL: "flasker-og-daaser/sodavand",
    headline: () => "Sodavand dåser",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Ramløse - Coca-Cola - Pepsi - Red Bull ...</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="4px 0 0 0" textCenter>
          <Image
            maxHeight="36px"
            imageHeight="36px"
            name="produkter-oelvand.coca-cola-zero-daase-33cl.png"
          />
          <Image
            maxHeight="36px"
            imageHeight="36px"
            marginLeft="1px"
            name="produkter-oelvand.5113-flaske.png"
          />
        </Col>
      </Row>
    ),
    beforeItem: {
      4053: <ItemSubHeader>Danskvand</ItemSubHeader>,
      5453: <ItemSubHeader>Light / sukkerfri sodavand</ItemSubHeader>,
      5353: <ItemSubHeader>Alm. sodavand</ItemSubHeader>,
    },
    items: [
      4053, // Ramløse dåse
      4063, // Ramløse cit dåse

      5453, // Coca-Cola Zero dåse
      4333, // Faxe Kondi 0kal Dåse
      52113, // Jolly Appelsin Light Dåse
      4363, // Pepsi Max Dåse
      3123, // Tuborg Squash Light Dåse
      9573, // Red Bull Light Dåse
      4383, // Faxe Kondi Booster Sort Zero Dåse

      // 5333, // Carlsberg Sport Dåse
      5353, // Coca-Cola dåse
      5113, // Fanta Orange dåse
      4313, // Faxe Kondi dåse
      3113, // Squash dåse
      9563, // Red Bull Dåse
      9483, // San P. Limonata Dåse
      9473, // San P. Aranciata Dåse
      9523, // San P. Aranci Rossa Dåse
      9503, // San P. Pompelmo Dåse
      9443, // San P. Melograno E Arancia
    ],
  },
  Sodavand50ClPlast: {
    name: "sodavand-50cl-plast",
    categoryURL: "flasker-og-daaser/sodavand",
    headline: () => "Sodavand plast, 50cl, 33cl & 25cl",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Kurvand - Coca Cola - Pepsi - Squash ...</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-oelvand.coca-cola-50cl.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.tuborg-squash-150cl.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.carlsberg-sport-50cl-v1.png"
          />
        </Col>
      </Row>
    ),
    beforeItem: {
      5052: <ItemSubHeader>Danskvand</ItemSubHeader>,
      5452: <ItemSubHeader>Light / sukkerfri sodavand</ItemSubHeader>,
      5332: <ItemSubHeader>Alm. sodavand</ItemSubHeader>,
      5354: <ItemSubHeader>25cl Sodavand Plast</ItemSubHeader>,
    },
    items: [
      5052, // Ramløse Natur brus
      5062, // Ramløse Citrus brus

      5452, // Coca-Cola Zero
      5122, // Fanta Zero Orange
      4334, // Faxe Kondi 0kal 33cl
      4332, // Faxe Kondi 0kal
      4362, // Pepsi Max
      4372, // Pepse Max Lime

      5332, // Carlsberg Sport
      5352, // Coca-Cola
      // 55352, // Polsk Cola
      5132, // Fanta Exotic
      5112, // Fanta Orange
      4314, // Faxe Kondi 33cl
      4312, // Faxe Kondi
      4352, // Pepsi
      2172, // Schweppes Lemon Fusion
      3112, // Tuborg Squash

      5354, // Coca-Cola 25cl
      5454, // Coca-Cola Zero 25cl
    ],
  },
  Sodavand150ClPlast: {
    name: "sodavand-150cl-plast",
    categoryURL: "flasker-og-daaser/sodavand",
    headline: () => "Sodavand plast, 150cl & 125cl",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Cola - Schweppes - Pepsi - Faxe Kondi ...</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-oelvand.tuborg-squash-150cl.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.schweppes-indian-tonic-150cl.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.coca-cola-150cl.png"
          />
        </Col>
      </Row>
    ),
    beforeItem: {
      5051: <ItemSubHeader>Danskvand</ItemSubHeader>,
      5451: <ItemSubHeader>Light / sukkerfri sodavand</ItemSubHeader>,
      5351: <ItemSubHeader>Alm. sodavand</ItemSubHeader>,
    },
    items: [
      5051, // Ramløse
      5061, // Ramløse Citrus

      5451, // Coca-Cola Zero
      4361, // Pepsi Max

      5351, // Coca-Cola
      5111, // Fanta Orange
      4311, // Faxe Kondi
      2221, // Schweppes Ginger Ale
      2171, // Schweppes Lemon Fusion
      2211, // Schweppes Tonic
      3111, // Tuborg Squash
    ],
  },
  ItalienskSodavand: {
    name: "italiensk-sodavand",
    categoryURL: "flasker-og-daaser/sodavand",
    headline: () => "Italiensk Sodavand",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Orangina - San Pellegrino ...</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-oelvand.san-pellegrino-limonata-20cl.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.6550-flaske.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.san-pellegrino-aranciata-rossa-20cl.png"
          />
        </Col>
      </Row>
    ),
    beforeItem: {
      6550: <ItemSubHeader>Glas</ItemSubHeader>,
      9483: <ItemSubHeader>Dåser</ItemSubHeader>,
    },
    items: [
      6550,
      9480,
      9470,
      9520,
      9483, // San P. Limonata Dåse
      9473, // San P. Aranciata Dåse
      9523, // San P. Aranci Rossa Dåse
      9503, // San P. Pompelmo Dåse
      9443, // San P. Melograno E Arancia
    ],
  },
  TilbudFadoel: {
    name: "tilbud-fadoel",
    categoryURL: "fadoel-og-anlaeg/tilbud",
    headline: () => <React.Fragment>Tilbud</React.Fragment>,
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Tilbud og kampagner!</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-fadoel.skovlyst-blanche.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-fadoel.noerrebro-bryghus-new-york-lager-40cl-glas.png"
          />
        </Col>
      </Row>
    ),
    items: [8285],
  },
  LysPilsner: {
    name: "lys-pilsner",
    categoryURL: "fadoel-og-anlaeg/fadoel",
    headline: () => "Pilsner",
    packaging: "på fustage",
    headlineSmall: (
      <Span italic fontSize="13px">
        - Udgør 50% af salget
      </Span>
    ),
    subHeadline: (
      <React.Fragment>
        <HiddenXS>
          Grøn Tuborg | Carlsberg | <HiddenMD>Birkebryg | </HiddenMD>Royal |
          Nørrebro<HiddenLG> | Heineken</HiddenLG> m.m.
        </HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-fadoel.groen-tuborg.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-fadoel.skovlyst-birkebryg.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-fadoel.heineken.png"
          />
        </Col>
      </Row>
    ),
    beforeItem: {
      3615: <ItemSubHeader>Populære Pilsnere</ItemSubHeader>,
      4815: <ItemSubHeader>Danske Pilsnere</ItemSubHeader>,
      10615: <ItemSubHeader>Økologiske Pilsnere</ItemSubHeader>,
      23615: <ItemSubHeader>Udenlandske Pilsnere</ItemSubHeader>,
      2616: <ItemSubHeaderModular />,
    },
    items: [
      // *
      // Populære Pilsnere
      // *
      3615, // Grøn Tuborg
      4615, // Royal Pilsner
      8215, // Skovlyst Birkebryg
      // *
      // Danske Pilsnere
      // *
      4815, // Albani Odense Pilsner
      2615, // Carlsberg Pilsner
      4715, // Ceres Top
      21615, // Fuglsang Økologisk Pilsner
      8315, // Hancock Fadøl
      8375, // Hancock Guld
      43615, // Mikkeller Pilsner
      // 8895, // Jacobsen Extra Pilsner
      29615, // Skands Humlefryd
      4725, // Thor Pilsner
      // 2625, // Wiibroe Pilsner
      // *
      // Økologiske Pilsnere
      // *
      10615, // Herslev Øko Pilsner
      18615, // Hornbeer Øko Godt Øl
      8115, // Nørrebro Øko New York Lager
      8015, // Nørrebro Øko Pilsner
      4625, // Royal Øko Pilsner
      19615, // Svaneke Øko Pilsner
      8225, // Skovlyst Signatur no23
      // *
      // Udenlandske Pilsnere
      // *
      23615, // Budweiser
      4965, // Birra Moretti
      4915, // Heineken
      8415, // Platan Pilsner
      8595, // Paulaner Oktoberfest
      8515, // San Miguel Especial
      23215, // Stella Artois
      // *
      // Modular20
      // *
      2616, // Carlsberg Pilsner Modular20
      3616, // Grøn Tuborg Modular20
      3675, // Tuborg Guld Modular20
      8805, // Kronenbourg 1664 Modular20
    ],
  },
  MoerkPilsner: {
    name: "moerk-pilsner",
    categoryURL: "fadoel-og-anlaeg/fadoel",
    headline: () => "Classic | Mørk Pilsner",
    packaging: "på fustage",
    headlineSmall: (
      <Span italic fontSize="13px">
        - Udgør 30% af salget
      </Span>
    ),
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Tuborg | Carlsberg 1883 | Egebryg | Svaneke m.m.</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-fadoel.tuborg-classic.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-fadoel.royal-classic.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-fadoel.4885-flaske.png"
          />
        </Col>
      </Row>
    ),
    beforeItem: {
      3655: <ItemSubHeader>Populære Classic / Mørke Pilsnere</ItemSubHeader>,
      4885: <ItemSubHeader>Danske Classic / Mørke Pilsnere</ItemSubHeader>,
      8055: <ItemSubHeader>Økologiske Classic / Mørke Pilsnere</ItemSubHeader>,
      8475: <ItemSubHeader>Udenlandske Classic / Mørke Pilsnere</ItemSubHeader>,
      3656: <ItemSubHeaderModular />,
    },
    items: [
      // *
      // Populære Classic / Mørke Pilsnere
      // *
      3655, // Tuborg Classic
      4655, // Royal Classic
      8255, // Skovlyst Egebryg
      // *
      // Danske Classic / Mørke Pilsnere
      // *
      4885, // Albani Odense 1859
      4855, // Albani Odense Classic
      12655, // Depot Classic
      21655, // Fuglsang Øko Classic
      21645, // Fuglsang Black Bird
      8365, // Hancock Black Lager
      43655, // Mikkeller Classic
      8385, // Old Gambrinus Dark
      10655, // Herslev Øko Classic
      29655, // Skands Classic
      4755, // Thor Classic
      3635, // Rød Tuborg
      // *
      // Økologiske Classic / Mørke Pilsnere
      // *
      8055, // Nørrebro Øko Classic
      4645, // Royal Øko Classic
      8295, // Skovlyst Signatur no 39 Classic
      19655, // Svaneke Øko Classic
      19675, // Svaneke Øko Mørk Guld
      // *
      // Udenlandske Classic / Mørke Pilsnere
      // *
      8475, // Demon Semimørk
      8455, // Granat Classic
      8485, // Merlin Tmavy
      // *
      // Modular20
      // *
      3656, // Tuborg Classic Modular20
    ],
  },
  Hvede: {
    name: "hvede",
    categoryURL: "fadoel-og-anlaeg/fadoel",
    headline: () => "Hvedeøl",
    packaging: "på fustage",
    headlineSmall: (
      <Span italic fontSize="13px">
        - Udgør 7,5% af salget
      </Span>
    ),
    subHeadline: (
      <React.Fragment>
        <HiddenXS>
          Hoegaarden | Edelweiss | Nørrebro<HiddenSM> | Kronenbourg</HiddenSM>{" "}
          m.m.
        </HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-fadoel.edelweiss-snowfresh.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-fadoel.hoegaarden.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-fadoel.8855-flaske.png"
          />
        </Col>
      </Row>
    ),
    items: [
      2485, // Kronenbourg Blanc
      8185, // Nørrebro Øko Blanche
      8285, // Skovlyst Blanche
      4685, // Royal Blanche
      40685, // Anarkist Bloody Weizen
      10685, // Herslev Øko Hvede
      8085, // Nørrebro Øko Stuykman Hvede
      4985, // Edelweiss Snowfresh
      8695, // Paulaner Hefe-Weissbier Dunkel
      8585, // Paulaner Hefe-Weissbier Naturtrüb
      8785, // Erdinger Hefe Weissbier
      8795, // Erdinger Dunkel Weissbier
      8815, // Grimbergen Blanche
    ],
  },
  IPA: {
    name: "ipa",
    categoryURL: "fadoel-og-anlaeg/fadoel",
    headline: () => "IPA",
    packaging: "på fustage",
    headlineSmall: (
      <Span italic fontSize="13px">
        - Udgør 5,5% af salget
      </Span>
    ),
    subHeadline: (
      <React.Fragment>
        <HiddenXS>
          Nørrebro | Skovlyst | Herslev | Hornbeer<HiddenSM> | Albani</HiddenSM>{" "}
          m.m.
        </HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-fadoel.skovlyst-india-pale-ale.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.8805-flaske.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-fadoel.flying-cloud-ipa.png"
          />
        </Col>
      </Row>
    ),
    items: [
      4635, // Royal IPA
      4835, // Albani Mosaic IPA
      40635, // Anarkist New England IPA
      40855, // Anarkist Hazy Juice DIPA
      40965, // Anarkist Fizzy Lime Fusion
      8635, // Founders All Day IPA
      8435, // Flying Cloud IPA
      43635, // Mikkeller IPA
      10105, // Herslev Øko Forårsbryg
      10635, // Herslev Øko India Pale Ale
      10795, // Herslev Øko Påskebryg
      18635, // Hornbeer Grisk IPA
      29635, // Skands My Hazy IPA
      8235, // Skovlyst Øko Lumberjack IPA
      39135, // Schiøtz IPA
      8135, // Nørrebro Øko Bombay India Pale Ale
    ],
  },
  LysAle: {
    name: "lys-ale",
    categoryURL: "fadoel-og-anlaeg/fadoel",
    headline: () => "Lys Ale",
    packaging: "på fustage",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Anarkist | Leffe | Grimbergen | Herslev</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-fadoel.leffe-blonde.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-fadoel.grimbergen-blonde.png"
          />
        </Col>
      </Row>
    ),
    beforeItem: {
      8855: <ItemSubHeaderModular />,
    },
    items: [
      4995, // Affligem Blonde
      40795, // Anarkist Sunny Bunny
      41625, // Fynsk Forår
      8845, // Grimbergen Blonde
      10665, // Herslev Øko Landøl 2,7%
      10625, // Herslev Øko Høst
      23885, // Leffe Blonde
      /*
      Modular20
      */
      8855, // Grimbergen Blonde Modular20
    ],
  },
  MoerkAle: {
    name: "moerk-ale",
    categoryURL: "fadoel-og-anlaeg/fadoel",
    headline: () => "Mørk Ale",
    packaging: "på fustage",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Leffe | Nørrebro | Herslev | Grimbergen m.m.</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-fadoel.grimbergen-blonde.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-fadoel.grimbergen-double-ambree.png"
          />
        </Col>
      </Row>
    ),
    beforeItem: {
      8835: <ItemSubHeaderModular />,
    },
    items: [
      8825, // Grimbergen Double Ambrée
      10895, // Herslev Øko Hyben
      23895, // Leffe Brune
      8035, // Nørrebro Øko Ravnsborg Rød
      29785, // Skands Blå Chimpanse
      39155, // Schiøtz Mørk Mumme
      /*
      Modular20
      */
      8835, // Grimbergen Double Ambrée Modular20
    ],
  },
  BrownAle: {
    name: "brown-ale",
    categoryURL: "fadoel-og-anlaeg/fadoel",
    headline: () => "Brown Ale",
    packaging: "på fustage",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>
          Ale no. 16 | Newcastle | Jacobsen | Skovlyst
          <HiddenSM> | Hornbeer</HiddenSM> m.m.
        </HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-fadoel.ale-no16.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-fadoel.newcastle-brown-ale.png"
          />
        </Col>
      </Row>
    ),
    beforeItem: {
      8885: <ItemSubHeaderModular />,
    },
    items: [
      12345, // Ale no. 16
      18875, // Hornbeer Øko Red Cow Brown Ale
      4955, // Newcastle Brown Ale
      /*
      Modular20
      */
      8885, // Jacobsen Brown Ale Modular20
    ],
  },
  PorterStout: {
    name: "porter-stout",
    categoryURL: "fadoel-og-anlaeg/fadoel",
    headline: () => "Porter | Stout",
    packaging: "på fustage",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Guinness | Murphys | Kissmeyer</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-fadoel.guinness.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-fadoel.4835-flaske.png"
          />
        </Col>
      </Row>
    ),
    items: [
      2745, // Guinness
      4945, // Murphy's Irish Stout
    ],
  },
  LetOel: {
    name: "let-oel",
    categoryURL: "fadoel-og-anlaeg/fadoel",
    headline: () => "Alkoholfri fadøl | Letøl",
    packaging: "på fustage",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>
          Teedawn | Platan Alkoholfri | Vestfyn Light | Herslev Øko Landøl
        </HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-fadoel.ch-alkoholfri.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-fadoel.willemoes-session-ipa.png"
          />
        </Col>
      </Row>
    ),
    beforeItem: {
      2556: <ItemSubHeaderModular />,
    },
    items: [
      33615, // Teedawn Gentle Lager Alkoholfri
      33685, // Teedawn Wheat Dreams Alkoholfri
      10665, // Herslev Øko Landøl 2,7%
      12515, // Vestfyen Light
      8425, // Platan Alkoholfri
      2556, // Carlsberg Nordic
    ],
    specificCategory: true,
  },
  VinFustage: {
    name: "vin-fustage",
    categoryURL: "fadoel-og-anlaeg/fadoel",
    headline: () => "Cocktails, Vin & Cider",
    packaging: "på fustage",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>
          Vino Frizzante | Apple Cider | Urban Cocktails | Nohrlund
        </HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-fadoel.nohrlund-den-roede-glas.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-fadoel.serena-vino-glas.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-fadoel.somersby-apple-cider.png"
          />
        </Col>
      </Row>
    ),
    items: [
      17865, // Serena Vino
      17875, // Vino Rosato
      4925, // Happy Joe Cider
      // 2815, // Somersby Apple Cider

      28114, // Excellent Tap Aperol Spritz Cocktail
      /*
      27025, // Urban Cocktails Dark'n Hurricane
      27035, // Urban Cocktails Champagne Brus
      27075, // Urban Cocktails Brandbil
      */

      // Nohrlund
      24115,
      24135,
      24085,
      24145,
      24095,
      // 24055,

      // Urban:
      27025,
      27035,
      27075,
    ],
  },
  ChristmasBeer: {
    name: "christmas-beer",
    categoryURL: "fadoel-og-anlaeg/fadoel",
    headline: () => (
      <React.Fragment>
        Juleøl {/* <Span green bold>TILBUD! Få fustager tilbage</Span> */}
      </React.Fragment>
    ),
    packaging: "på fustage",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>
          Juleøl tages ikke retur efter tirsdag den 10. december 2024
        </HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-fadoel.skovlyst-julebryg.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-fadoel.tuborg-julebryg.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-fadoel.royal-xmas-blaagran.png"
          />
        </Col>
      </Row>
    ),
    items: [
      3755, // Tuborg Julebryg
      10675, // Herslev Øko Juleøl
      8865, // Grimbergen Nöel
      8275, // Skovlyst Julebryg
      19775, // Svaneke Julebryg
      8075, // Nørrebro Jule IPA
      12375, // Ale no. 24
      4875, // Albani Rødhætte
      4675, // Royal Xmas Blågran
      40675, // Anarkist Jule Ale
    ],
    specificCategory: true,
  },
  OktoberFest: {
    name: "oktoberfest",
    categoryURL: "fadoel-og-anlaeg/fadoel",
    headline: () => "Oktoberfestøl",
    packaging: "på fustage",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Paulaner Oktober Fest | Erdinger Dunkel m.m.</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-fadoel.erdinger-dunkel-weissbier.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-fadoel.8495-flaske.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-fadoel.8885-flaske.png"
          />
        </Col>
      </Row>
    ),
    items: [
      8595, // Paulaner Oktober Fest
      8265, // Skovlyst Oktoberfest
      8285, // Skovlyst Blanche
      8585, // Paulaner Hefe-Weissbier Naturtrüb
      8785, // Erdinger Hefe Weissbier
      8795, // Erdinger Dunkel Weissbier
      3901, // 1 liters ølglas
    ],
    specificCategory: true,
  },
  PaaskeOel: {
    name: "paaskeoel",
    categoryURL: "fadoel-og-anlaeg/fadoel",
    headline: () => "Påskeøl",
    packaging: "på fustage",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Herslev Øko Påskebryg | Herslev Øko Forårsbryg</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-fadoel.herslev-paaskebryg-logo.jpg"
          />
        </Col>
      </Row>
    ),
    items: [
      10795,
      10105,
      3795,
      // 8085, // Dronning Louises Paaske Ale
    ],
    specificCategory: true,
  },
  Modular20: {
    name: "modular20",
    categoryURL: "fadoel-og-anlaeg/fadoel",
    headline: () => "Modular 20 / Flex 20",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>
          Carlsberg | Tuborg | Grimbergen | Jacobsen | Kronenbourg
        </HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="modular20.groen-tuborg.png"
            marginRight="5px"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="modular20.tuborg-classic.png"
          />
        </Col>
      </Row>
    ),
    beforeItem: {
      2616: <ItemSubHeaderModular title="Modular 20 til eget anlæg" />,
      2614: <ItemSubHeaderModular title="Flex 20 (med slange)" />,
    },
    items: [
      // Modular 20 eget anlæg
      2616, // Carlsberg Pilsner
      2556, // Carlsberg Nordic Ale
      3616, // Grøn Tuborg
      3656, // Tuborg Classic
      3675, // Tuborg Guld
      8855, // Grimbergen Blonde
      8835, // Grimbergen Double
      8885, // Jacobsen Brown Ale
      8895, // Jacobsen IPA
      8805, // Kronenbourg 1664
      8875, // Jacobsen Christmas Ale
      8715, // Brooklyn Lager

      // Flex 20
      2614, // Flex 20 Carlsberg Pilsner
      3614, // Flex 20 Grøn Tuborg
      3654, // Flex 20 Tuborg Classic
    ],
    specificCategory: true,
  },
  RoyalTilbud: {
    name: "royal-tilbud",
    headline: () => (
      <React.Fragment>
        <Span green fontWeight="900">
          TILBUD: Fustager med gratis dåseøl
        </Span>
      </React.Fragment>
    ),
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Nørrebro - Royal</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="style.RoyalUnibrew_logo.jpg"
          />
        </Col>
      </Row>
    ),
    items: [8115, 8015, 8185, 8085, 8035, 8055, 8135, 4635],
    specificCategory: true,
  },
  HeinekenTilbud: {
    name: "heineken-tilbud",
    headline: () => (
      <React.Fragment>
        <Span green fontWeight="900">
          TILBUD: Fustager med gratis glas
        </Span>
      </React.Fragment>
    ),
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Heineken</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-fadoel.heineken.png"
          />
        </Col>
      </Row>
    ),
    items: [4915],
    specificCategory: true,
  },
  /*
  RoyalTilbud: {
    name: 'royal-tilbud',
    headline: () => (<React.Fragment><Span green fontWeight="900" fontSize="30px">TILBUD:</Span> Fustager med gratis dåseøl</React.Fragment>),
    subHeadline: (
      <React.Fragment>
        <HiddenXS>
          Royal - Albani - Ceres - Thor - Heineken
        </HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image maxHeight="42px" imageHeight="42px" name="style.RoyalUnibrew_logo.jpg" />
        </Col>
      </Row>
    ),
    beforeItem: {
      4815: (
        <ItemSubHeader>Pilsner</ItemSubHeader>
      ),
      4855: (
        <ItemSubHeader>Classic | Mørk Pilsner</ItemSubHeader>
      ),
      4835: (
        <ItemSubHeader>IPA</ItemSubHeader>
      ),
      4995: (
        <ItemSubHeader>Lys Ale</ItemSubHeader>
      ),
      39155: (
        <ItemSubHeader>Mørk Ale</ItemSubHeader>
      ),
      4955: (
        <ItemSubHeader>Brown Ale</ItemSubHeader>
      ),
      4835: (
        <ItemSubHeader>Porter | Stout</ItemSubHeader>
      ),
      4925: (
        <ItemSubHeader>Cocktails og cider på fustage</ItemSubHeader>
      ),
      4875: (
        <ItemSubHeader>Jule fadøl</ItemSubHeader>
      ),
    },
    items: [
      // Pilsner
      4815, // Albani Odense Pilsner
      4715, // Ceres Top
      4915, // Heineken
      4615, // Royal Pilsner
      4625, // Royal Øko Pils
      4725, // Thor Pilsner

      // Classic | Mørk Pilsner
      4855, // Albani Odense Classic
      4885, // Albani Odense 1859
      4655, // Royal Classic
      4645, // Royal Øko Classic
      4755, // Thor Classic

      // IPA
      4835, // Albani Mosaic IPA
      4635, // Royal IPA
      39135, // Schiøtz Gylden IPA

      // Lys Ale
      4995, // Affligem Blondes

      // Mørk Ale
      39155, // Schiøtz Mørk Mumme

      // Brown Ale
      4955, // Newcastle Brown Ale

      // Porter | Stout
      4945, // Murphy\'s Irish Stout

      // Cocktails og cider på fustage
      4925, // Happy Joe Dry Apple Cider

      // Jule fadøl
      4875, // Albani Rødhætte
      // 40675, // Anarkist Christmas Ale
      4675, // Royal Xmas Blågran
    ],
    specificCategory: true,
  },
  */
  /*
  HerslevBryghus: {
    name: 'herslev-bryghus',
    headline: () => (<React.Fragment><Span green fontWeight="900" fontSize="30px">TILBUD:</Span> Fustager med gratis smagskasse</React.Fragment>),
    subHeadline: (
      <React.Fragment>
        <HiddenXS>
          Herslev Bryghus
        </HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image maxHeight="42px" imageHeight="42px" name="style.herslev-logo.png" />
        </Col>
      </Row>
    ),
    beforeItem: {
      4855: (
        <ItemSubHeader>Pilsner</ItemSubHeader>
      ),
      10685: (
        <ItemSubHeader>Hvedeøl</ItemSubHeader>
      ),
      10635: (
        <ItemSubHeader>IPA</ItemSubHeader>
      ),
      10895: (
        <ItemSubHeader>Mørk Ale</ItemSubHeader>
      ),
      10675: (
        <ItemSubHeader>Jule fadøl</ItemSubHeader>
      ),
    },
    items: [
      // Pilsner
      10615, // Herslev Øko Pilsner

      // Hvedeøl
      10685, // Herslev Øko Hvede

      // IPA
      10635, // Herslev Øko India Pale Ale
      10105, // Herslev Øko Forårsbryg
      10795, // Herslev Øko Påskebryg

      // Lys Ale
      10665, // Herslev Øko Landøl 2,7%

      // Mørk Ale
      10895, // Herslev Øko Hyben

      // Jule fadøl
      10675, // Herslev Øko Juleøl
    ],
    specificCategory: true,
  },
  */
  /*
  CocktailsGlas: {
    name: 'cocktailsglas',
    headline: () => 'Cocktails',
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Økologiske cocktails fra Nohrlund til enhver anledning</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image maxHeight="42px" imageHeight="42px" name="produkter-oelvand.nohrlund-den-roede-flaske.png" />
          <Image maxHeight="42px" imageHeight="42px" marginLeft="5px" name="produkter-oelvand.nohrlund-den-hvide-flaske.png" />
          <Image maxHeight="42px" imageHeight="42px" marginLeft="5px" name="produkter-oelvand.nohrlund-den-gule-flaske.png" />
        </Col>
      </Row>
    ),
    items: [
      24010,
      24020,
      24030,
      24040,
      24050,
      24060,
    ],
  },
  */
  Glas: {
    name: "glas",
    categoryURL: "fadoel-og-anlaeg/oelglas",
    dontShowCategoryInSearch: 1,
    headline: () => "Glas",
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="36px"
            imageHeight="36px"
            name="produkter-oelvand.7774-flaske.png"
          />
          <Image
            maxHeight="36px"
            imageHeight="36px"
            marginLeft="5px"
            name="produkter-oelvand.7773-flaske.png"
          />
        </Col>
      </Row>
    ),
    items: [
      7773, // 30cl krus
      7774, // 40cl krus
    ],
  },
  Teknikker: {
    name: "teknikker",
    categoryURL: "fadoel-og-anlaeg/reservedele",
    headline: () => "Teknikker og Gavekort",
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="reservedele.teknikker.png"
          />
        </Col>
      </Row>
    ),
    items: [
      772, // Teknikker pr. time
      790, // Gavekort
    ],
  },
  KulsyreTab: {
    name: "kulsyre",
    categoryURL: "fadoel-og-anlaeg/kulsyre",
    headline: () => "Kulsyre",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Mængderabat ved køb af flere flasker</HiddenXS>
      </React.Fragment>
    ),
    alwaysOpen: true,
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-oelvand.8940-flaske.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.8944-flaske.png"
          />
        </Col>
      </Row>
    ),
    items: [
      8944,
      8940,
      21130,
      20601, // Trykregulator til danskvand
      // 21132,
      8941,
      8945,
    ],
  },
  Kulsyre: {
    name: "kulsyre",
    categoryURL: "fadoel-og-anlaeg/kulsyre",
    dontShowCategoryInSearch: 1,
    headline: () => "Kulsyre",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Mængderabat ved køb af flere flasker</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-oelvand.8940-flaske.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="produkter-oelvand.8944-flaske.png"
          />
        </Col>
      </Row>
    ),
    items: [8944, 8940],
  },
  Rensning: {
    name: "rensning",
    categoryURL: "fadoel-og-anlaeg/reservedele",
    headline: () => "Rensning",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Rensedunke - rensevæske - anstikker ...</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="reservedele.rensedunk1-stor.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            marginLeft="5px"
            name="reservedele.rensevaeske5liter-stor.png"
          />
        </Col>
      </Row>
    ),
    items: [8975, 20820, 8965, 20800, 20805, 20810, 20815],
  },
  KulsyreTilkobling: {
    name: "kulsyre-tilkobling",
    categoryURL: "fadoel-og-anlaeg/reservedele",
    headline: () => "Kulsyre tilkobling",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>
          Trygregulator - kulsyrenøgle<HiddenSM> - studser</HiddenSM> -
          sodastream ...
        </HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="reservedele.trykregulator-stor.png"
          />
        </Col>
      </Row>
    ),
    items: [
      20600,
      20601, // Trykregulator til danskvand
      20942, // Kulsyreslange 1/4" (6,3mm)
      20615,
      21130, // Sodastream tilkobling
      // 21132, // Quooker tilkobling
      20630,
      20631,
      20605,
      20610,
      20500,
      20620,
      20625,
      20640,
      20940,
      20902,
      21140,
    ],
  },
  OelTilkobling: {
    name: "oel-tilkobling",
    categoryURL: "fadoel-og-anlaeg/reservedele",
    headline: () => "Øl tilkobling",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Fadkoblinger - Studser - Tilbageløbspakning ...</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="reservedele.fadkobling-s.png"
          />
        </Col>
      </Row>
    ),
    items: [
      20400, // Fadkobling S-system m. studs
      20405,
      20406,
      20410,
      20415,
      20439, // Fadkobling D-system
      20437, // Fadkobling S3-system
      20420, // Fadkobling M-system
      20430, // Cornelius kobling
      20435, // Keykeg kobling
      20819, // Adapter S-kobling til Keykeg
      20985, // Klemmering 7,0 mm
      20986, // Klemmering 10,5 mm
      20987, // Klemmering 12,3 mm
      20988, // Klemmering 13,3 mm
      20989, // Klemmering 14,0 mm
      20990, // Klemmering 14,8 mm
      20505,
      20510,
      20515,
      20520,
      20525,
      20530,
    ],
  },
  OelTapning: {
    name: "oel-tapning",
    categoryURL: "fadoel-og-anlaeg/reservedele",
    headline: () => "Øl aftapning",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>
          Fadølshane med indsats - Topring<HiddenSM> - Drypbakke</HiddenSM> -
          Lynkobling ...
        </HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxWidth="100%"
            imageHeight="42px"
            name="reservedele.plastik-drypbakke-med-rist1.png"
          />
        </Col>
      </Row>
    ),
    items: [
      21100, // Plastikskaller til topring sæt | Alle typer
      21110, // Topring oval
      21111, // Topring rund
      20707,
      20708,
      20709,
      20710,
      20715,
      20720,
      21115,
      21117,
      21210,
      20930,
      20955,
      20905,
      20910,
      20915,
      20920,
      20925,
      22120,
      21251, // John Guest &frac12;"=12,7mm gevind- &frac38;"=9,5mm slange
      21252,
      21253,
      21254,
    ],
  },
  KoeleAnlaeg: {
    name: "koeleanlaeg",
    categoryURL: "fadoel-og-anlaeg/reservedele",
    headline: () => "Køleanlæg",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Termostat - Motor ...</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxWidth="100%"
            imageHeight="42px"
            name="reservedele.omreremotor-dan-koeler.jpg"
          />
        </Col>
      </Row>
    ),
    items: [21000, 21005, 21010, 21015, 21020],
  },
  Is: {
    name: "is",
    categoryURL: "fadoel-og-anlaeg/oelglas",
    headline: () => "Isterninger",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>
          Ingen returret på isterninger
          <br />
          Manglende returnering af termotønde faktureres med{" "}
          <DelayedRendering>
            {() => <Money money="7500" momsIndication />}
          </DelayedRendering>
        </HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxWidth="100%"
            imageHeight="42px"
            name="diverse.is-cylinder.png"
          />
        </Col>
      </Row>
    ),
    alwaysOpen: true,
    items: [
      // 'Is1',
      // 'Is2',
      50025,
      50026,
    ],
  },
  OelGlas: {
    name: "oelglas",
    categoryURL: "fadoel-og-anlaeg/oelglas",
    headline: () => (
      <React.Fragment>
        <Span bold fontSize="22px">
          Ølglas
        </Span>
        <Span fontSize="16px">&nbsp;- Ubrudte ruller tages retur</Span>
      </React.Fragment>
    ),
    subHeadline: (
      <React.Fragment>
        <HiddenXS>
          Der er forskel på plastikglas, Det forekommer at plastikglas købt i
          detailhandlen eller engros markeder har en usynlig belægning indvendig
          i glassene der gør at øllet{" "}
          <LinkStyled to="/videoer/forskel-fadoelsglas">
            skummer utrolig meget
          </LinkStyled>{" "}
          når der hældes op. Vores glas har ikke denne belægning.
        </HiddenXS>
      </React.Fragment>
    ),
    alwaysOpen: true,
    items: [
      7773, // 30cl krus
      7774, // 40cl krus
      3776, // Bærehank 5 glas
      3904, // Ølkande 2 liter
      3901, // 1 liters ølglas
    ],
    specificCategory: true, // This is the category we want these items to map to
  },
  Packs: {
    name: "packs",
    // dontShowCategoryInSearch: 2,
    items: [
      "pack_1",
      "pack_13",
      "pack_3",
      "pack_4",
      "pack_ultimate",
      "pack_2",
      3907,
      "pack_wagon",
      611,
    ],
  },

  ReturPantKasserUdenFlasker: {
    name: "returpant-kasser-uden-flasker",
    headline: () => "Returpant: Kasser uden flasker",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Tomme kasser fra Coca-Cola, Ramløse, Søbogaard ...</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="returpant.cola-kasse.png"
          />
        </Col>
      </Row>
    ),
    items: [800, 801, 805],
  },
  ReturPantKasserMedFlasker: {
    name: "returpant-kasser-med-flasker",
    headline: () => "Returpant: Kasser med flasker",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Kasser med flasker fra San Pellegrino, Ramløse ...</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="returpant.cola-med-flasker.png"
          />
        </Col>
      </Row>
    ),
    items: [830, 824, 829, 819, 812, 813, 820],
  },
  ReturPantFustagerOgKulsyre: {
    name: "returpant-fustager-og-kulsyre",
    headline: () => "Returpant: Fustager og kulsyre",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Brugte fustager og kulsyre ...</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-fadoel.carlsberg-fustage.png"
          />
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="produkter-oelvand.8940-flaske.png"
          />
        </Col>
      </Row>
    ),
    items: [875, 876, 877, 878, 879, 870, 872],
  },
  ReturPantPoserOgPaller: {
    name: "returpant-poser-og-paller",
    headline: () => "Returpant: Poser og paller",
    subHeadline: (
      <React.Fragment>
        <HiddenXS>Poser til Dansk Retursystem og EUR-palle ...</HiddenXS>
      </React.Fragment>
    ),
    image: (
      <Row>
        <Col xs={12} padding="0" textCenter>
          <Image
            maxHeight="42px"
            imageHeight="42px"
            name="retursystem.retursystem-posemfyld.png"
          />
        </Col>
      </Row>
    ),
    items: [864, 865, 887],
  },
};
export const pageCategories = {
  FadoelOgAnlaeg: {
    url: "/fadoel-og-anlaeg",
    subPages: {
      Fadoel: {
        url: "/fadoel",
        categories: [
          // 'TilbudFadoel',
          // "HeinekenTilbud",
          // "RoyalTilbud",
          // 'HerslevBryghus',
          "LysPilsner",
          "MoerkPilsner",
          "Hvede",
          "IPA",
          "BrownAle",
          "MoerkAle",
          "LysAle",
          "LetOel",
          "VinFustage",
          "PorterStout",
          "Modular20",
          "PaaskeOel",
          "OktoberFest",
          "ChristmasBeer",
        ],
      },
      AnlaegOgBar: {
        url: "/anlaeg-og-bar",
        categories: [
          "Packs",
          // 'ExtraPacks',
        ],
      },
      OelGlas: {
        url: "/oelglas",
        categories: ["Is", "OelGlas"],
      },
      Reservedele: {
        url: "/reservedele",
        categories: [
          "Rensning",
          "KulsyreTilkobling",
          "OelTilkobling",
          "OelTapning",
          "KoeleAnlaeg",
          "Teknikker",
        ],
      },
      Kulsyre: {
        url: "/kulsyre",
        categories: ["KulsyreTab"],
      },
    },
  },
  FlaskerOgDaaser: {
    url: "/flasker-og-daaser",
    subPages: {
      JuiceOgSaft: {
        url: "/juice-og-saft",
        categories: [
          "AntonSafter",
          "SoebogaardSafter",
          "Juice25Cl",
          "CocioChokolademaelk",
          "IceTea",
        ],
      },
      Kildevand: {
        url: "/kildevand",
        categories: [
          "KildevandUdenBrus",
          "KildevandMedBrus",
          "KildevandMedSmag",
          "KildevandPaller",
          "TyskKildevandUdenBrusUdenPant",
          "SvenskVitaminWell",
        ],
      },
      OelOgAlkohol: {
        url: "/oel-og-alkohol",
        categories: [
          "AlkoholfriOelKasser",
          "Oel33ClGlas",
          "OelCiderDaase",
          "AlkoholCocktails",
          "ChristmasGlass",
          "Vin",
          "Glas",
          "Kulsyre",
        ],
      },
      Sodavand: {
        url: "/sodavand",
        categories: [
          // 'ImportTilbud',
          "SodavandGlas",
          "OekologiskSodavand",
          "Sodavand2550ClDaaser",
          "Sodavand50ClPlast",
          "Sodavand150ClPlast",
          "ItalienskSodavand",
        ],
      },
    },
  },
  Retur: {
    url: "/retur",
    subPages: {
      Pant: {
        url: "pant",
        categories: [
          "ReturPantKasserUdenFlasker",
          "ReturPantKasserMedFlasker",
          "ReturPantFustagerOgKulsyre",
          "ReturPantPoserOgPaller",
        ],
      },
    },
  },
};
