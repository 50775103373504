import React from "react";
import styled from "styled-components/macro";
import { Route } from "react-router-dom";
import { Money } from "../../../containers/commerce";
import withDeliveryInfo from "../../../containers/commerce/withDeliveryInfo";
import { deliveryTypes } from "../../../features/commerce";
import { Paragraph, LinkStyled, Span } from "../../typography";
import ErrorText from "../../../pages/Bestil/Levering/LeveringComponenter/DeliveryErrorText";
import { Table, Td } from "../../tables";

const ErrorTextWrapNoStyle = ({ children, error, choosen, ...otherProps }) => (
  <Paragraph italic {...otherProps}>
    {children}
  </Paragraph>
);
const ErrorTextWrap = styled(ErrorTextWrapNoStyle)`
  ${({ error, theme }) => {
    if (error) {
      return `
        color: ${theme.colors.colorRed};
      `;
    }
    return "";
  }}
`;
const DeliveryContainer = ({ error, children, ...rest }) => (
  <div {...rest}>
    {children}
    {error && (
      <React.Fragment>
        <ErrorTextWrap error={error}>
          <ErrorText error={error} />
        </ErrorTextWrap>
        <Paragraph>
          <Route
            path="/bestil/flasker-og-daaser"
            render={() => (
              <LinkStyled underline to="/bestil/flasker-og-daaser/levering">
                Vælg leveringstype her
              </LinkStyled>
            )}
          />
          <Route
            path="/bestil/fadoel-og-anlaeg"
            render={() => (
              <LinkStyled underline to="/bestil/fadoel-og-anlaeg/levering">
                Vælg leveringstype her
              </LinkStyled>
            )}
          />
        </Paragraph>
      </React.Fragment>
    )}
  </div>
);
const StyledDeliveryContainer = styled(DeliveryContainer)``;

const NoDelivery = ({ error, deliveryCostWithoutFees, deliveryFeesCost }) => (
  <StyledDeliveryContainer error={error}>
    <Table paddingTd="0">
      <tbody>
        <tr>
          <Td>
            <Span padding="10px 0">Henter selv i Brøndby</Span>
          </Td>
          <Td textRight>
            <Span padding="10px 0">
              {deliveryCostWithoutFees > 0 && (
                <Money money={deliveryCostWithoutFees} />
              )}
            </Span>
          </Td>
        </tr>
        {deliveryFeesCost > 0 && (
          <tr>
            <Td>
              <Span padding="0 0 10px 0">+Tillæg</Span>
            </Td>
            <Td width="85px" textRight>
              <Span padding="0 0 10px 0">
                <Money money={deliveryFeesCost} />
              </Span>
            </Td>
          </tr>
        )}
        <tr>
          <Td colspan="2">
            <Span padding="10px 0">
              <LinkStyled to="/bestil/flasker-og-daaser/levering">
                Vælg anden leveringsmetode
              </LinkStyled>
            </Span>
          </Td>
        </tr>
      </tbody>
    </Table>
  </StyledDeliveryContainer>
);

const FreeDelivery = ({
  error,
  deliveryCost,
  deliveryDate,
  deliveryCostWithoutFees,
  deliveryFeesCost,
  externalDeliveryFeeCost,
  effectiveZip,
}) => {
  let freeText = "Gratis levering";
  let costText = "Levering:";
  if (!deliveryDate) {
    freeText = "Gratis levering 1-2 hverdage";
    costText = "Levering 1-2 hverdage:";
  }
  return (
    <StyledDeliveryContainer error={error}>
      <Table paddingTd="0">
        <tbody>
          <tr>
            {deliveryCost === 0 ? (
              <Td>
                <Span padding="10px 0">{freeText}</Span>
              </Td>
            ) : (
              <React.Fragment>
                <Td textLeft paddingTop="0px">
                  <Span padding="10px 0">{costText}</Span>
                </Td>
                <Td textRight paddingTop="0px" width="100px">
                  <Span padding="10px 0">
                    {deliveryCostWithoutFees >= 0 && (
                      <Money money={deliveryCostWithoutFees} />
                    )}
                  </Span>
                </Td>
              </React.Fragment>
            )}
          </tr>
          {externalDeliveryFeeCost > 0 && (
            <tr>
              <Td textLeft>
                <Span padding="0 0 10px 0">
                  +Fjernlevering ({effectiveZip})
                </Span>
              </Td>
              <Td textRight>
                <Span padding="0 0 10px 0">
                  <Money money={externalDeliveryFeeCost} />
                </Span>
              </Td>
            </tr>
          )}
          {deliveryFeesCost > 0 && (
            <tr>
              <Td textLeft>
                <Span padding="0 0 10px 0">+Tillæg</Span>
              </Td>
              <Td textRight>
                <Span padding="0 0 10px 0">
                  <Money money={deliveryFeesCost} />
                </Span>
              </Td>
            </tr>
          )}
          <tr>
            <Td colspan="2">
              <Span padding="10px 0">
                <LinkStyled to="/bestil/flasker-og-daaser/levering">
                  Vælg anden leveringsmetode
                </LinkStyled>
              </Span>
            </Td>
          </tr>
        </tbody>
      </Table>
    </StyledDeliveryContainer>
  );
};

const DeliveryAndPickup = ({
  error,
  deliveryCostWithoutFees,
  deliveryFeesCost,
  externalDeliveryFeeCost,
  effectiveZip,
}) => (
  <StyledDeliveryContainer error={error}>
    <Table paddingTd="0">
      <tbody>
        <tr>
          <Td>
            <Span padding="10px 0">
              Levering &amp; afhentning
              <br />
              samt opsætning:
            </Span>
          </Td>
          <Td textRight>
            <Span padding="10px 0">
              <Money money={deliveryCostWithoutFees} />
            </Span>
          </Td>
        </tr>
        {externalDeliveryFeeCost > 0 && (
          <tr>
            <Td textLeft>
              <Span padding="0 0 10px 0">+Fjernlevering ({effectiveZip})</Span>
            </Td>
            <Td textRight>
              <Span padding="0 0 10px 0">
                <Money money={externalDeliveryFeeCost} />
              </Span>
            </Td>
          </tr>
        )}
        {deliveryFeesCost > 0 && (
          <tr>
            <Td textLeft>
              <Span padding="0 0 10px 0">+Tillæg</Span>
            </Td>
            <Td textRight>
              <Span padding="0 0 10px 0">
                <Money money={deliveryFeesCost} />
              </Span>
            </Td>
          </tr>
        )}
        <tr>
          <Td colspan="2">
            <Span padding="10px 0">
              <LinkStyled to="/bestil/flasker-og-daaser/levering">
                Vælg anden leveringsmetode
              </LinkStyled>
            </Span>
          </Td>
        </tr>
      </tbody>
    </Table>
  </StyledDeliveryContainer>
);

export default withDeliveryInfo(
  ({
    error,
    deliveryType,
    hasLeje,
    deliveryCost,
    deliveryCostWithoutFees,
    deliveryFeesCost,
    deliveryDate,
    externalDeliveryFeeCost,
    effectiveZip,
  }) => {
    let Component = null;
    switch (deliveryType) {
      case deliveryTypes.no:
        Component = NoDelivery;
        break;
      case deliveryTypes.delivery:
        Component = FreeDelivery;
        break;
      case deliveryTypes.deliveryAndPickup:
        Component = DeliveryAndPickup;
        break;
      default:
        return null;
    }
    return (
      <Component
        error={error}
        deliveryType={deliveryType}
        hasLeje={hasLeje}
        deliveryCost={deliveryCost}
        deliveryDate={deliveryDate}
        deliveryCostWithoutFees={deliveryCostWithoutFees}
        deliveryFeesCost={deliveryFeesCost}
        externalDeliveryFeeCost={externalDeliveryFeeCost}
        effectiveZip={effectiveZip}
      />
    );
  }
);
